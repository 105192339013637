.btn {
  display: inline-block;
  font: inherit;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font: inherit;
  border: none;
  padding: 1em;
  color: inherit;
  letter-spacing: 0.125em;
  font-family: 'Maison Neue Extended', 'Maison Neue', 'Helvetica Neue',
    sans-serif;
}

.modal {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translate3d(0, 100vh, 0);
  transition: all 0.5s;
  pointer-events: none;
  z-index: 100;
  display: flex;
  color: white;
  background-color: black;
  opacity: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.modal:target {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
  opacity: 1;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  display: block;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  padding-top: 3rem;
  color: inherit;
  font-size: 2em;
}

.modal__close::after {
  content: '+';
  display: block;
  position: absolute;
  height: 1rem;
  width: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
  top: 50%;
  left: 50%;
  transform: rotate(45deg);
}

.modal__content {
  padding: 1em;
  width: 100%;
  margin: auto;
  max-width: 400px;
}

.flash {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  color: white;
  animation: flashOut 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  pointer-events: none;
  z-index: 100;
  text-align: center;
}

.flash__item {
  margin: 1rem auto;
}

@keyframes flashOut {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
