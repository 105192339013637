@font-face {
  font-family: 'Maison Neue';
  src: url('./fonts/MaisonNeue-Book.woff2') format('woff2'),
    url('./fonts/MaisonNeue-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Maison Neue';
  src: url('./fonts/MaisonNeue-Demi.woff2') format('woff2'),
    url('./fonts/MaisonNeue-Demi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Maison Neue';
  src: url('./fonts/MaisonNeue-ExtraBold.woff2') format('woff2'),
    url('./fonts/MaisonNeue-ExtraBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Maison Neue Extended';
  src: url('./fonts/MaisonNeueExtended-ExtraBold.woff2') format('woff2'),
    url('./fonts/MaisonNeueExtended-ExtraBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 16px;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2em;
}
