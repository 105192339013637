@import './colors.css';
@import './animations';

#globe {
  display: none;
  position: fixed;
  bottom: -30px;
  left: 0;
  right: 0;
  height: 15vh;
  overflow: hidden;
  transition: transform 1s;
}

#globe figcaption {
  color: transparent;
  display: block;
  position: absolute;
  left: -50vw;
  top: 0;
  width: 200vw;
  height: 200vw;
  border-radius: 100%;
  overflow: hidden;
}

#globe figcaption::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 33%,
    rgba(0, 0, 0, 1)
  );
}

@supports (width: 1vw) {
  #globe {
    display: block;
  }

  #globe:hover {
    transform: translate3d(0, -20px, 0);
  }
}

/*@supports (width: 1vw) {*/
/*#globe {*/
/*display: block;*/
/*top: */
/*position: fixed;*/
/*background: blue;*/
/*display: block;*/
/*border-radius: 100%;*/
/*height: 100vmin;*/
/*width: 100vmin;*/
/*transition: transform 1s;*/
/*z-index: 1;*/
/*margin-left: -50vmin;*/
/*left: 50%;*/
/*}*/
/*}*/
